import { registerApplication, start } from "single-spa";
import config from "./config";

async function getUser() {
  const response = await fetch(`${config.appURL}/user`, {
    credentials: "include",
  });
  const json = await response.json();
  if (!json.token) {
    window.location.replace(`${config.appURL}/login`);
  } else {
    const data = await fetch(
      `${config.appURL}/api/tenant-service/users/ShowWithConfig?id=${json.users[0].id}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await data.json();

    const Arr = Object.keys(res.data.access); 
  
    const filter = { ...res.data.access }
  
    const filterFinish = Arr.map(
      ( element ) => {
        if(filter[element]["A"] !== true){
          delete filter[element]
        }
  
      })
    
      res.data["access"] = filter

    registerApplication({
      name: "@unity/header",
      app: () => System.import("@unity/header"),
      activeWhen: ["/"],
      customProps: {
        domElement: document.getElementById("header"),
        auth: res.data,
        expressURL: config.appURL
      },
    });

    registerApplication({
      name: "@unity/sidebar",
      app: () => System.import("@unity/sidebar"),
      activeWhen: ["/"],
      customProps: {
        domElement: document.getElementById("sidebar"),
        auth: res.data,
        theme: res.data.theme,
        custom: filter,
        expressURL: config.appURL
      },
    });

    
    Object.keys(filter).map((key, val) => {
      registerApplication({
        name: `@unity/${key}`,
        app: () => System.import(`@unity/${key}`),
        activeWhen: [filter[key].options.active_when],
        customProps: {
          domElement: document.getElementById("content"),
          auth: res.data,
          theme: res.data.theme,
          //custom: filter[key].options.props,
          custom: filter,
          registrations: json.users[0].registrations
        },
      });
    }
    
    )




    start({
      urlRerouteOnly: true,
    });
  }
}

getUser();
